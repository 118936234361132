import React, {lazy, Suspense} from 'react';
import Classes from './body.module.css';
import Ava from '../../../assets/driiliLogo.png';
import {Switch, Redirect, Route} from 'react-router-dom';
import Loading from '../../suspense/suspense'
const Home = lazy(() => import('../../../pages/home/eventHome'));
const Create = lazy(() =>
  import('../../../pages/createInvestment/eventCreateInvestment')
);
const Reinvest = lazy(() => import('../../../pages/reinvest/enventReinvest'));
const Withdraw = lazy(() =>
  import('../../../pages/withdrawal/eventWithdrawal')
);
const History = lazy(() => import('../../../pages/history/eventHistory'));
const Profile = lazy(() => import('../../../pages/profile/eventProfile'));
const Referral = lazy(() => import('../../../pages/referral/eventReferral'));

// import

const Body = () => {
  return (
    <div className={Classes.bodyCover}>
      <nav className={Classes.navCover}>
        <div className={Classes.navLeft}>
          <div className={Classes.logged_user_i}>
            <div className={Classes.avatar_w}>
              <img alt="Avatar Profile" src={Ava} />
            </div>
          </div>
        </div>
      </nav>
      <div className={Classes.pageCover}>
        <Switch>
          <Redirect from="/" to="/home" exact />
          <Route
            path="/home"
            // exact
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <Home {...props} />{' '}
              </Suspense>
            )}
          />
          <Route
            exact
            path="/create-investment"
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <Create {...props} />{' '}
              </Suspense>
            )}
          />
          <Route
            exact
            path="/reinvest"
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <Reinvest {...props} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/withdrawal"
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <Withdraw {...props} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/transaction-history"
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <History {...props} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/my-profile"
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <Profile {...props} />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/my-referral"
            component={(props) => (
              <Suspense fallback={<Loading />}>
                <Referral {...props} />
              </Suspense>
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Body;
